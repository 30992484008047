const Footer = () => {
  return (
    <footer>
      <p>
        Tyler Wright's 2021 Claim Academy Java Full Stack Software Developer
        Final Capstone Project
      </p>
    </footer>
  );
};

export default Footer;
